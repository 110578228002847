import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { downgradeComponent, UpgradeModule } from '@angular/upgrade/static';

import { AppComponent } from './app.component';
import { AdvancedSearchModule, AdvancedSearchContainerComponent } from '@entegra-europe/advanced-search';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    UpgradeModule,
    AdvancedSearchModule,
    StoreDevtoolsModule.instrument()
  ],
  providers: [],
})

export class AppModule {
  constructor(private upgrade: UpgradeModule) { }
  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['beagle'], { strictDi: false });
  }
}

// Angular Components to be downgraded for use in AngularJS
declare var angular: any;
angular.module('beagle')
  .directive(
    'appRoot',
    // @ts-ignore - TS doesn't think angular is defined but it is clearly defined at line 33...
    downgradeComponent({component: AppComponent}) as angular.IDirectiveFactory
  )
  .directive(
    'cdbAdvancedSearchContainer',
    downgradeComponent({component: AdvancedSearchContainerComponent})
  );
